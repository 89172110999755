<template>
  <div>
    <div
      class="columns is-mobile is-vcentered"
      :class="{ 'is-alert': icons.alert }"
    >
      <div class="column is-1">
        <button
          v-if="listing.vrm"
          class="button is-size-7"
          :class="{ 'is-loading': $wait.is(`companion-${listing.vrm}`) }"
          :disabled="!isCompanionUser || $wait.is('companion-*')"
          @click="goToCompanion"
        >
          {{ listing.vrm }}
        </button>
        <span class="has-text-grey-light" v-else>
          N/A
        </span>
      </div>
      <div
        v-if="hasColumn('description')"
        :class="['column', marketDemand ? 'is-2' : 'is-4']"
        class="column is-1"
      >
        <p class="has-text-grey-light" v-if="!description">
          N/A
        </p>
        <span
          v-else-if="description.truncated"
          v-tippy="{ placement: 'right' }"
          :title="description.full"
        >
          {{ description.truncated }}
        </span>
        <p v-else>
          {{ description.full }}
        </p>
      </div>
      <div v-if="hasColumn('price')" class="column is-1">
        <span v-if="listing.price">
          <div :class="icons.price" class="tag is-rounded has-text-weight-bold">
            {{ pounds(listing.price) }}
          </div>
        </span>
        <span class="has-text-grey-light" v-else>
          N/A
        </span>
      </div>
      <div v-if="hasColumn('pricePosition')" class="column is-1">
        <span v-if="listing.percentageValue">
          <span>{{ Math.round(listing.percentageValue) }}%</span>
          <a
            class="icon is-small"
            :title="priceInfo.pricePositionTooltip"
            v-tippy
          >
            <i :class="icons.pricePosition" />
          </a>
        </span>
        <span class="has-text-grey-light" v-else>
          N/A
        </span>
      </div>
      <div v-if="hasColumn('suggestedPrice')" class="column is-1">
        <div
          class="tag is-success is-rounded has-text-weight-bold"
          v-if="listing.suggestedPrice"
        >
          <span v-if="!priceInfo.suggestedPrice">
            <span>
              {{ pounds(listing.suggestedPrice) }}
            </span>
          </span>
          <span v-else class="icon">
            <i class="fas fa-check" />
          </span>
        </div>
        <span class="has-text-grey-light" v-else>
          N/A
        </span>
      </div>
      <div v-if="hasColumn('mileage')" class="column is-1">
        <span v-if="listing.mileage">
          <span>{{ formatNumber(listing.mileage) }}</span>
        </span>
        <span class="has-text-grey-light" v-else>
          N/A
        </span>
      </div>
      <div v-if="hasColumn('daysInStock')" class="column is-1">
        <span v-if="listing.daysInStock">
          <span>{{ listing.daysInStock }}</span>
          <span class="icon is-small" v-if="icons.daysInStock">
            <i :class="icons.daysInStock" />
          </span>
        </span>
        <span class="has-text-grey-light" v-else>
          N/A
        </span>
      </div>
      <div v-if="hasColumn('daysSinceLastPriceChange')" class="column is-1">
        <a
          class="has-text-info"
          @click="openPriceChangeModal"
          v-if="priceInfo.priceChange"
        >
          <u>
            {{ priceInfo.priceChangeText }}
          </u>
        </a>
        <span v-else-if="priceInfo.priceChangeText">
          {{ priceInfo.priceChangeText }}
        </span>
        <span class="has-text-grey-light" v-else>
          N/A
        </span>
        <span class="icon is-small" v-if="icons.lastPriceChange">
          <i :class="icons.lastPriceChange" />
        </span>
      </div>
      <div v-if="hasColumn('vehicleStatus')" class="column is-1">
        <span v-if="listing.vehicleStatus">
          <span class="is-capitalized">{{ listing.vehicleStatus }}</span>
        </span>
        <span class="has-text-grey-light" v-else>
          N/A
        </span>
      </div>
      <div v-if="hasColumn('desirability') && marketDemand" class="column is-2">
        <DesirabilityBar
          v-bind="{ number: true, desirability: listing.desirability }"
          v-if="listing.desirability"
        />
        <span class="has-text-grey-light" v-else>
          N/A
        </span>
      </div>
    </div>
    <hr class="hr is-marginless" />
  </div>
</template>

<script>
import { pounds } from 'core/utils/format'
import { formatNumber } from 'accounting'
import * as stockviewListing from '../services/stockview-listing.service'
import * as companion from 'modules/companion/services'
export default {
  name: 'StockviewListingCard',
  components: {
    DesirabilityBar: () =>
      import('core/modules/desirability-bar/components/DesirabilityBar')
  },
  props: {
    listing: {
      type: Object,
      required: true
    },
    marketDemand: {
      type: Boolean
    },
    isCompanionUser: {
      type: Number,
      required: true
    },
    properties: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  computed: {
    description() {
      return stockviewListing.getDescription({
        listing: this.listing,
        maxLength: 26
      })
    },
    icons() {
      return stockviewListing.getIcons(this.listing)
    },
    priceInfo() {
      return stockviewListing.getPriceInfo(this.listing)
    }
  },
  methods: {
    pounds,
    formatNumber,
    openPriceChangeModal() {
      stockviewListing.openPriceChangeModal(this.listing)
    },
    async goToCompanion() {
      const { vrm, mileage } = this.listing
      if (!this.isCompanionUser) return
      try {
        this.$wait.start(`companion-${vrm}`)
        await companion.value({ vrm, mileage })
        this.$mxp.track('stockview_stock_companion_lookup')
      } finally {
        this.$wait.end(`companion-${vrm}`)
      }
    },
    hasColumn(column) {
      return stockviewListing.hasColumn(this.properties, column)
    }
  }
}
</script>

<style lang="sass" scoped>
.is-alert
  background: transparentize($danger, 0.75)
</style>
